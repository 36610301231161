/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import chunk from 'lodash.chunk';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
import ArchivePostSingle from 'components/ArchivePostSingle';
import BlogTop from 'components/BlogTop';

// import { Main } from 'styles/blog';
import 'styles/blog.scss';

const BlogIndex = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
    // allContentfulCategory: { edges: allCategory = [] },
    allContentfulCustomerStory: { nodes: allCustomerStories = [] },
  },
  location,
}) => {
  const [posts, setPosts] = useState([]);

  const [pages, setPages] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState('');

  const postsPerPage = 9;

  // eslint-disable-next-line no-unused-vars
  const categoryToggle = useCallback(
    (input) => () => {
      if (input !== category) {
        setCurrentPage(0);
        setPosts([]);
        setPages([]);
      }
      setCategory(() => input);
    },
    [category],
  );

  const getBlogPosts = useCallback(
    (type) =>
      allPosts.filter((p) => {
        if (type === 'kb') {
          if (category) {
            if (p.node.category?.some((e) => e.name === category)) {
              return p.node.knowledgeBox === true;
            }
            return false;
          }
          return p.node.knowledgeBox === true;
        }
        if (category) {
          if (p.node.category?.some((e) => e.name === category)) {
            setPosts([]);
            return !p.node.knowledgeBox;
          }
          setPosts([]);
          return false;
        }
        setPosts([]);
        return !p.node.knowledgeBox;
      }),
    [allPosts, category],
  );

  const ConcatAllPosts = useCallback(
    () =>
      getBlogPosts('post')
        .map(({ node }) => ({ ...node }))
        .concat(allCustomerStories.slice(1))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    [],
  );

  useEffect(() => {
    setPages(chunk(ConcatAllPosts(), postsPerPage));
  }, [getBlogPosts]);

  useEffect(() => {
    setPosts(() => [...(pages[currentPage] ? pages[currentPage] : [])]);
  }, [pages, currentPage]);

  const nextPage = useCallback(() => {
    if (currentPage < pages.length) {
      setCurrentPage((state) => state + 1);
    }
  }, [currentPage, pages]);
  const prevPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage((state) => state - 1);
    }
  }, [currentPage, pages]);

  const pageChange = useCallback((i) => () => setCurrentPage(i), []);

  return (
    <Layout location={location} showBottomPopup={false}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Blog | ruttl"
            description="Improve your knowledge and stay updated with new trends in designing with ruttl's blog page that is regularly updated with latest content on web designing."
            url="https://ruttl.com/blog/"
          />
          <main className="blog-styled-main">
            <BlogTop />
            <section className="post-list-main">
              {/* <div className="container">
              <p className="bold post-list-main--subhead">
                Our thoughts and observations on business, partners, nutrients,
                productivity, Science, &amp; more.
              </p>
            </div> */}
              {true && (
                <div className="container">
                  <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                    {/* {console.log(
                      posts
                        .map(({ node }) => ({ ...node }))
                        .concat(allCustomerStories.slice(1))
                        .sort(
                          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                        ),
                    )} */}
                    {posts.map((node) => (
                      <React.Fragment key={node?.id}>
                        <ArchivePostSingle
                          link={
                            node?.publishDate
                              ? `/blog/${node?.slug}/`
                              : `/customer-stories/${node?.slug}/`
                          }
                          title={node?.title}
                          image={
                            node?.archiveThumbnail
                              ? node?.archiveThumbnail?.fluid
                              : node.featuredImage.fluid
                          }
                          excerpt={
                            node?.excerpt?.excerpt
                              ? node?.excerpt?.excerpt
                              : node?.excerpt
                          }
                          category={
                            node?.category
                              ? node?.category
                              : [{ name: node?.tag }]
                          }
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="pagination flex">
                    <button
                      type="button"
                      className={currentPage === 0 ? 'disabled' : ''}
                      onClick={prevPage}>
                      Previous
                    </button>
                    <div className="page-numbers flex">
                      <button
                        className={`${currentPage === 0 ? 'active' : ''}`}
                        type="button"
                        onClick={pageChange(0)}>
                        1
                      </button>
                      {currentPage > 3 ? (
                        <span className="page-dots">...</span>
                      ) : null}
                      {[...Array(pages.length).keys()].map((i) =>
                        i === 0 ||
                        i === pages.length - 1 ||
                        i + 3 <= currentPage ||
                        i - 3 >= currentPage ? null : (
                          <button
                            key={i}
                            className={`${i === currentPage ? 'active' : ''}`}
                            type="button"
                            onClick={pageChange(i)}>
                            {i + 1}
                          </button>
                        ),
                      )}
                      {currentPage < pages.length - 4 ? (
                        <span className="page-dots">...</span>
                      ) : null}
                      <button
                        className={`${
                          currentPage === pages.length - 1 ? 'active' : ''
                        }`}
                        type="button"
                        onClick={pageChange(pages.length - 1)}>
                        {pages.length}
                      </button>
                    </div>
                    <button
                      type="button"
                      className={
                        currentPage === pages.length - 1 ? 'disabled' : ''
                      }
                      onClick={nextPage}>
                      Next
                    </button>
                  </div>
                </div>
              )}
            </section>
            <GetStartedCenter toggleSignup={toggleSignup} />
          </main>
        </>
      )}
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC } #      limit: 10
      filter: {
        category: {
          elemMatch: { name: { nin: ["Product Updates", "Product Videos"] } }
        }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
    allContentfulCustomerStory(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        excerpt
        title
        tag
        slug
        createdAt
        featuredImage {
          fluid(
            maxWidth: 600
            maxHeight: 456
            quality: 70
            resizingBehavior: THUMB
          ) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
